import clsx from "clsx"
import classes from "./AddLesson.module.scss"
import { useCopies } from "../../../contexts/CopiesContext"
import CalendarStrip from "../../../components/CalendarStrip/CalendarStrip"
import Button from "../../../components/Button/Button"
import NavigationBar from "../../../components/NavigationBar/NavigationBar"
import { BASE_URL, post } from "../../../utils/requests"
import { useEffect, useMemo, useState } from "react"
import { getLocalTimeString, setTime } from "../../../utils/time"
import { template } from "lodash"
import { useSettings } from "../../../contexts/SettingsContext"
import { useNavigate } from "react-router-dom"
import { track } from "../../../utils/track"

const AddLesson = () => {
  const {
    copies: { add_lesson: copies, event_types },
  } = useCopies()
  const {
    settings: { selectedTimeslots, user },
    setSelectedSlots,
  } = useSettings()

  const [slotsByDate, setSlotsByDate] = useState<
    Record<number, EventDetails[]>
  >({})

  const navigate = useNavigate()

  const [selectedDate, setSelectedDate] = useState<Date>(new Date())

  const activeDate = new Date()

  const onDateChange = (date: Date) => {
    setSelectedDate(date)
    fetchTimeSlots(date)
  }

  const currentEvents = useMemo(() => {
    const absoluteTime = setTime(selectedDate, 0, 0).getTime()
    return slotsByDate[absoluteTime]
  }, [selectedDate, slotsByDate])

  const fetchTimeSlots = async (date: Date) => {
    const events = await post<EventDetails[]>(`${BASE_URL}/events`, {
      date,
      slotsOnly: true,
    })

    const absoluteTime = setTime(date, 0, 0).getTime()

    setSlotsByDate((prevSlots) => ({ ...prevSlots, [absoluteTime]: events }))
  }

  useEffect(() => {
    fetchTimeSlots(activeDate)
  }, [])

  const selectionLength = Object.values(selectedTimeslots).length

  const onTimeslotClick = (slot: EventDetails) => {
    const startTime = new Date(slot.startTime)
    const timestamp = startTime.getTime()

    const selectedSlotsClone = { ...selectedTimeslots }
    if (timestamp in selectedSlotsClone) {
      track("Time Slot Toggle Off", {
        count: selectionLength,
      })
      delete selectedSlotsClone[timestamp]
      setSelectedSlots(selectedSlotsClone)
      return
    }

    track("Time Slot Toggle On", {
      count: selectionLength,
    })
    selectedSlotsClone[timestamp] = slot
    setSelectedSlots(selectedSlotsClone)
  }

  const ctaLabel =
    selectionLength === 0
      ? copies.none_selected_continue_cta
      : selectionLength === 1
      ? copies.one_selected_continue_cta
      : template(copies.multiple_selected_continue_cta)({
          number: selectionLength,
        })

  const onContinueClick = () => {
    track("Request Lessons Continue Press", { count: selectionLength })
    navigate("/lesson/add/summary")
  }

  return (
    <>
      <div className={classes.root}>
        <NavigationBar title={copies.title} />
        <div className={clsx(classes.wrapper, "padded")}>
          <CalendarStrip
            activeDate={selectedDate}
            futureOnly
            onDateChange={onDateChange}
          />
          <div className={classes.content}>
            {currentEvents ? (
              currentEvents.map((item) =>
                item?.student?.uid === user.id ? (
                  item.type === "lessonRequest" ? (
                    <Button disabled variant="outlined">
                      {copies.your_requested_lesson}
                    </Button>
                  ) : (
                    <Button disabled variant="outlined">
                      {template(copies.your_event)({
                        eventType: event_types[item.type],
                      })}
                    </Button>
                  )
                ) : (
                  <Button
                    variant={
                      new Date(item.startTime).getTime() in selectedTimeslots
                        ? "secondary"
                        : "outlined"
                    }
                    onClick={() => onTimeslotClick(item)}
                  >
                    {getLocalTimeString(new Date(item.startTime))}
                  </Button>
                )
              )
            ) : (
              <div>
                <h1>{copies.no_available_slots}</h1>
              </div>
            )}
          </div>
          <div className={classes.button}>
            <Button disabled={selectionLength < 1} onClick={onContinueClick}>
              {ctaLabel}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddLesson
