export type Language = "en" | "he"
export interface Copies {
  homepage_content: {
    title: string
    cta: string
    questions: string
    contactUsCta: string
  }
  login_phone_number: {
    title: string
    subtitle: string
    input_placeholder: string
    input_disclaimer: string
    cta_label: string
    cta_disclaimer_content: string
    cta_disclaimer_cta_label: string
  }
  verify_otp: {
    title: string
    subtitle: string
    input_placeholder: string
    cta_label: string
    resend_cta_label: string
    resend_in_cta_label: string
    change_number_cta_label: string
    no_otp_error: string
  }

  no_access: {
    title: string
    subtitle: string
    cta_label: string
    logout: string
  }
  no_teacher: {
    title: string
    subtitle: string
    cta_label: string
    logout: string
  }
  no_internet: {
    title: string
    subtitle: string
  }
  lessons: {
    title: string
    cta_label: string
    lessons_in_progress: string
    next_lesson: string
    future_lessons: string
    waiting_for_approval: string
    waiting_for_cancelation: string
    lessons_history: string

    no_lessons: string
    schedule_lesson_cta: string

    subtitle_empty: string
    subtitle_one_lesson: string
    subtitle_multiple_lessons: string
  }
  add_lesson: {
    title: string
    your_event: string
    your_requested_lesson: string

    no_available_slots: string
    no_available_slots_subtitle: string
    none_selected_continue_cta: string
    one_selected_continue_cta: string
    multiple_selected_continue_cta: string
  }
  pickup_location: {
    btn_label: string
  }
  dropoff_location: {
    btn_label: string
  }
  location_picker: {
    search_placeholder: string
  }
  lesson_summary: {
    title: string
    cancel_lesson_cta: string
    cta_label: string
  }
  lesson_details: {
    title: string

    time_label: string
    date_label: string

    license_plate: string

    lesson_number: string
    car: string
    car_type: string
    car_color: string

    all_lessons_cta: string
    cancel_cta: string
  }

  cancel_lesson_modal: {
    title: string
    subtitle: string

    confirm_cta: string
    dismiss_cta: string
  }
  disable_account_modal: {
    title: string
    subtitle: string

    confirm_cta: string
    dismiss_cta: string
  }

  event_types: {
    break: string
    lesson: string
    vacation: string
    test: string
    internalTest: string
  }

  students: {
    title: string
  }
  settings: {
    title: string
    account: string
    account_subtitle: string
    preferences: string
    notifications: string
    notifications_subtitle: string
    language: string
    language_subtitle: string
    chat: string
    chat_subtitle: string
    disable_account: string
    logout: string
  }
  notifications_settings: {
    title: string
    get_sms: string
    get_push: string

    save_cta: string
  }

  gear_types: {
    manual: string
    automatic: string
  }
  days: {
    long: [string, string, string, string, string, string, string]
    short: [string, string, string, string, string, string, string]
  }

  error_snackbar: {
    dismiss_cta: string
  }

  errors: {
    unknown_error: string
  }

  relative_dates: {
    today: string
    tomorrow: string
    yesterday: string
    next: string
    last: string
    now: string
  }
  generic_snackbars: {
    changes_saved: string
    unknown_error: string
  }

  events: {
    event_number: string
  }

  constants: {
    contact_phone_number: string
  }

  complete_details: {
    title: string
    subtitle: string
    cta_label: string
  }

  sign: {
    title: string
    subtitle: string

    done_cta: string
    clear_cta: string

    signed_title: string
    signed_subtitle: string
  }

  unsubscribe: {
    title: string
    subtitle: string
    labels: {
      canGetSMS: string
      canGetReminderSMS: string
      canGetDocumentsSMS: string
    }
    all_off: string
    all_on: string
  }

  teacher_home: {
    title: string
    connect_google_calendar: string
    connect_google_calendar_helper: string
  }
  teacher_trial_home: {
    trial_ends_label: string
  }
  connect_calendar: {
    title: string
  }
}

export const copies: Record<Language, Copies> = {
  en: {
    homepage_content: {
      title: "Your Road to License\nBegins Here.",
      cta: "Get Started",
      questions: "Questions?",
      contactUsCta: "Contact Us",
    },
    login_phone_number: {
      title: "Welcome.",
      subtitle: "Log in to your account",

      input_placeholder: "Israeli Phone Number",
      input_disclaimer:
        "You will receive an SMS verification that may apply message and data rates.",

      cta_label: "Continue",
      cta_disclaimer_content: "By continuing, you agree to our",
      cta_disclaimer_cta_label: "terms of service.",
    },

    verify_otp: {
      title: "We Sent You a Code",
      subtitle: "Enter the OTP that we have sent via SMS to the ${phoneNumber}",
      input_placeholder: "Your Code",
      cta_label: "Verify",
      resend_cta_label: "Resend Code",
      resend_in_cta_label: "Resend Code (${seconds})",
      change_number_cta_label: "Change Number",
      no_otp_error: "Please Provide A Valid OTP Code",
    },
    no_access: {
      title: "Access Required",
      subtitle:
        "This screen required special access.\nIf you think we made a mistake and you should have access to this page, you can contact us.",
      cta_label: "Contact Us",
      logout: "Logout",
    },
    no_teacher: {
      title: "Thanks For Joining Dio!",
      subtitle:
        "Seems like no teacher has added you as their student.\nDon't worry, you've done your part and soon your teacher will update your details.\nIf you think we made a mistake and you should have access to this page, you can contact us.",
      cta_label: "Contact Us",
      logout: "Logout",
    },
    no_internet: {
      title: "Whoops! No Internet Connection",
      subtitle:
        "Please check your connection and try again. It seems like you're offline at the moment.",
    },
    lessons: {
      title: "Lessons",
      cta_label: "New Lesson",
      lessons_in_progress: "Current Event",
      next_lesson: "Next Event",
      future_lessons: "Future Events",
      lessons_history: "Events History",
      waiting_for_approval: "Waiting For Approval",
      waiting_for_cancelation: "Waiting For The Cancelation",
      no_lessons: "No Future Events Was Scheduled",
      schedule_lesson_cta: "Request New Lesson",
      subtitle_empty: "No Events Yet",
      subtitle_one_lesson: "1 Lesson Done",
      subtitle_multiple_lessons: "${number} Lessons Done",
    },
    add_lesson: {
      title: "New Lesson",
      your_event: "Your ${eventType}",
      your_requested_lesson: "You Have Requested This Lesson",
      no_available_slots: "No Available Time Slots",
      no_available_slots_subtitle:
        "Seems like there's no availability for the selected time.",
      none_selected_continue_cta: "Choose One Or More Time Slots",
      one_selected_continue_cta: "Request 1 Lesson",
      multiple_selected_continue_cta: "Request ${number} Lessons",
    },
    location_picker: {
      search_placeholder: "Search",
    },
    lesson_summary: {
      title: "Selection Summary",
      cancel_lesson_cta: "Remove",
      cta_label: "Confirm",
    },
    pickup_location: {
      btn_label: "Set As Pickup Location",
    },
    dropoff_location: {
      btn_label: "Set As Drop-Off Location",
    },
    lesson_details: {
      title: "Lesson Details",
      time_label: "Lesson Time",
      date_label: "Lesson Date",
      license_plate: "License Plate",
      lesson_number: "Lesson Number",
      car: "Car",
      car_type: "Type",
      car_color: "Color",
      all_lessons_cta: "All Lessons",
      cancel_cta: "Request To Cancel",
    },
    cancel_lesson_modal: {
      title: "Are You Sure?",
      subtitle:
        "You are about to cancel the lesson on ${date}, ${time}. Are you sure?",
      confirm_cta: "Yes, Request to Cancel",
      dismiss_cta: "No, Keep The Lesson",
    },
    disable_account_modal: {
      title: "Are You Sure?",
      subtitle:
        "You are about to delete you account. This means you will not be able to login again, you will not be able to book new lessons and you will lose your lessons history.",
      confirm_cta: "Yes, Delete My Account",
      dismiss_cta: "No, Go Back",
    },
    event_types: {
      break: "Break",
      lesson: "Lesson",
      vacation: "Vacation",
      test: "Test",
      internalTest: "Internal Test",
    },
    students: {
      title: "Students",
    },
    settings: {
      title: "Settings",
      account: "Account",
      account_subtitle: "Manage your account",
      preferences: "Preferences",
      notifications: "Notifications",
      notifications_subtitle: "Choose which notifications to get",
      language: "Language",
      language_subtitle: "Change the language of the app",
      logout: "Logout",
      chat: "Chat With Us",
      chat_subtitle:
        "Having troubles? Want to suggest new features? We are here for you",
      disable_account: "Delete My Account",
    },
    notifications_settings: {
      title: "Notifications",
      get_sms: "Get SMS Notifications",
      get_push: "Get Push Notifications",
      save_cta: "Save Changes",
    },
    gear_types: {
      manual: "Manual",
      automatic: "Automatic",
    },
    days: {
      long: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      short: ["SAN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    },
    error_snackbar: {
      dismiss_cta: "Dismiss",
    },
    errors: {
      unknown_error: "Unknown Error",
    },
    relative_dates: {
      today: "Today",
      tomorrow: "Tomorrow",
      yesterday: "Yesterday",
      next: "Next ${day}",
      last: "Last ${day}",
      now: "Now",
    },
    generic_snackbars: {
      changes_saved: "Changes Saved Successfully",
      unknown_error: "Unknown Error Saving Your Changes",
    },
    events: {
      event_number: "${eventType} Number ${number}",
    },
    constants: {
      contact_phone_number: "972555049898",
    },
    complete_details: {
      title: "Complete Your Profile",
      subtitle: "Just a Few More Details and You'll Be Ready to Go!",
      cta_label: "Continue",
    },
    sign: {
      title: "Hello ${name}",
      subtitle: 'You\'ve been invited to sign on "${documentTitle}"',

      done_cta: "Sign",
      clear_cta: "Clear",

      signed_title: "Signed, Thank You",
      signed_subtitle: "This request was signed successfuly",
    },
    unsubscribe: {
      title: "Unsubscribe",
      subtitle: "Change your subscription preferences for ${phoneNumber}",
      labels: {
        canGetSMS: "Notifications",
        canGetReminderSMS: "Reminders",
        canGetDocumentsSMS: "Documents Sent To You",
      },
      all_off: "Turn Off Everything",
      all_on: "Turn On Everything",
    },

    teacher_home: {
      title: "Hi ${firstName}",
      connect_google_calendar: "Sync Google Calendar",
      connect_google_calendar_helper:
        "Connect your calendar from Google to sync the events with Dio",
    },

    teacher_trial_home: {
      trial_ends_label: "Your trial ends at",
    },
    connect_calendar: {
      title: "Sync Calendars",
    },
  },
  he: {
    homepage_content: {
      title: "הדרך שלך לרישיון\nמתחילה כאן",
      cta: "התחל",
      questions: "שאלות?",
      contactUsCta: "צרו קשר",
    },
    login_phone_number: {
      title: "ברוכים השבים.",
      subtitle: "התחבר למשתמש שלך",

      input_placeholder: "מספר פלאפון",
      input_disclaimer:
        "תקבל הודעת SMS לאימות המספר, ייתכנו עלויות לפי התעריף של חברת הסלולר.",

      cta_label: "המשך",
      cta_disclaimer_content: "לחיצה על המשך מהווה הסכמה של",
      cta_disclaimer_cta_label: "תנאי השימוש.",
    },

    verify_otp: {
      title: "שלחנו לך קוד",
      subtitle: "הכנס את הקוד שקיבלת בהודעת SMS במספר ${phoneNumber}",
      input_placeholder: "הקוד שלך",
      cta_label: "אימות",
      resend_cta_label: "שליחה מחדש",
      resend_in_cta_label: "שליחה מחדש (${seconds})",
      change_number_cta_label: "שינוי מספר",
      no_otp_error: "יש להכניס את הקוד שהתקבל בהודעת SMS",
    },
    no_access: {
      title: "אין גישה לעמוד זה",
      subtitle:
        "העמוד הזה דורש גישה מיוחדת.\nאם עשינו טעות ואמורה להיות לך גישה לעמוד זה, ניתן ליצור איתנו קשר..",
      cta_label: "יצירת קשר",
      logout: "התנתק",
    },
    no_teacher: {
      title: "תודה שהצטרפת ל-Dio!",
      subtitle:
        "נראה שאף מורה לא הוסיף אתכם כתלמידים שלו.\nאל דאגה! מהצד שלכם הכל מוכן ובקרוב המורה שלכם יעדכן את הפרטים שלכם ותוכלו לגשת לאפליקציה. כשזה יקרה, נעדכן אתכם ב-SMS.\nאם אתם חושבים שעשינו טעות, תמיד אפשר ליצור איתנו קשר.",
      cta_label: "יצירת קשר",
      logout: "התנתק",
    },
    no_internet: {
      title: "אין חיבור לאינטרנט",
      subtitle: "אנא בדקו את חיבור האינטרנט שלכם ונסו שנית.",
    },
    lessons: {
      title: "שיעורים",
      cta_label: "שיעור חדש",
      lessons_in_progress: "האירוע הנוכחי",
      next_lesson: "האירוע הבא",
      future_lessons: "אירועים עתידיים",
      lessons_history: "היסטוריית אירועים",
      waiting_for_approval: "מחכים לאישור",
      waiting_for_cancelation: "בקשות לביטול",
      no_lessons: "לא נקבעו שיעורים עתידיים",
      schedule_lesson_cta: "לחצו כאן לקביעת שיעור",
      subtitle_empty: "לא בוצעו שיעורי נהיגה",
      subtitle_one_lesson: "שיעור אחת הושלם",
      subtitle_multiple_lessons: "${number} שיעורים הושלמו",
    },
    add_lesson: {
      title: "שיעור חדש",
      your_event: "ה${eventType} שלך",
      your_requested_lesson: "השיעור שלך ממתין לאישור",
      no_available_slots: "אין שעות זמינות",
      no_available_slots_subtitle: "לא מצאנו שעות זמינות ביום הזה.",
      none_selected_continue_cta: "בחרו את מספר השיעורים הרצוי",
      one_selected_continue_cta: "שליחת בקשה לשיעור אחד",
      multiple_selected_continue_cta: "שליחת בקשה ל-${number} שיעורים",
    },
    location_picker: {
      search_placeholder: "חיפוש",
    },
    pickup_location: {
      btn_label: "בחר כנקודת איסוף",
    },
    dropoff_location: {
      btn_label: "בחר כנקודת החזרה",
    },
    lesson_details: {
      title: "פרטי השיעור",
      time_label: "זמן השיעור",
      date_label: "תאריך",
      lesson_number: "שיעור מספר",
      car: "רכב",
      car_type: "סוג",
      car_color: "צבע",
      all_lessons_cta: "כל השיעורים",
      license_plate: "מספר לוחית רישוי",
      cancel_cta: "ביטול השיעור",
    },
    cancel_lesson_modal: {
      title: "ביטול שיעור",
      subtitle:
        "אתם עומדים להגיש בקשת ביטול לשיעור בתאריך ${date}, בשעה ${time}. האם אתם בטוחים שברצונכם להמשיך?",
      confirm_cta: "כן, בטל את השיעור",
      dismiss_cta: "לא, השאר את השיעור",
    },
    disable_account_modal: {
      title: "האם אתה בטוח?",
      subtitle:
        "אתם עומדים למחוק את המשתמש שלכם. לאחר מחיקת המשתמש תאבדו גישה למשתמש, לא תוכלו לקבוע שיעורים נוספים ותאבדו את היסטוריית השיעורים שלכם.",
      confirm_cta: "כן, מחיקת המשתמש",
      dismiss_cta: "לא, חזרה",
    },
    lesson_summary: {
      title: "סיכום הבקשה",
      cancel_lesson_cta: "מחיקה",
      cta_label: "אישור ושליחת בקשה",
    },
    event_types: {
      break: "הפסקה",
      lesson: "שיעור",
      vacation: "חופשה",
      test: "טסט",
      internalTest: "טסט פנימי",
    },
    students: {
      title: "תלמידים",
    },
    settings: {
      title: "הגדרות",
      account: "חשבון",
      account_subtitle: "ניהול החשבון",
      preferences: "העדפות",
      notifications: "התראות",
      notifications_subtitle: "בחר אילו התראות לקבל",
      language: "שפה",
      language_subtitle: "שינוי השפה של האפליקציה",
      logout: "התנתק",
      chat: "יצירת קשר",
      chat_subtitle:
        "נתקלתם בבעיה? יש לכם רעיון לפיצ׳ר חדש? אנחנו זמינים ב-WhatsApp לשירותכם",
      disable_account: "מחיקת המשתמש",
    },
    notifications_settings: {
      title: "התראות",
      get_sms: "קבלת התראות ב-SMS",
      get_push: "קבלת התראות Push",
      save_cta: "שמירת השינויים",
    },
    gear_types: {
      manual: "ידני",
      automatic: "אוטומט",
    },
    days: {
      long: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
      short: ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"],
    },
    error_snackbar: {
      dismiss_cta: "הסתר",
    },
    errors: {
      unknown_error: "תקלה לא ידועה",
    },
    relative_dates: {
      today: "היום",
      tomorrow: "מחר",
      yesterday: "אתמול",
      next: "${day} הבא",
      last: "${day} שעבר",
      now: "עכשיו",
    },
    generic_snackbars: {
      changes_saved: "השינויים נשמרו בהצלחה",
      unknown_error: "שגיאה לא ידועה בשמירת השינויים",
    },
    events: {
      event_number: "${eventType} מספר ${number}",
    },
    constants: {
      contact_phone_number: "972555049898",
    },
    complete_details: {
      title: "השלמת פרטים חסרים",
      subtitle: "רק עוד כמה פרטים קטנים עליך ונהיה מוכנים לצאת לדרך!",
      cta_label: "המשך",
    },
    sign: {
      title: "שלום ${name}",
      subtitle: 'הוזמנת לחתום על "${documentTitle}"',

      done_cta: "שמירה",
      clear_cta: "נקה",

      signed_title: "נחתם, תודה רבה",
      signed_subtitle: "הבקשה נחתמה בהצלחה",
    },
    unsubscribe: {
      title: "העדפות SMS",
      subtitle: "עבור ${phoneNumber}",
      labels: {
        canGetSMS: "התראות",
        canGetReminderSMS: "תזכורות",
        canGetDocumentsSMS: "מסמכים שנשלחו אלייך",
      },

      all_off: "כבה הכל",
      all_on: "הדלק הכל",
    },

    teacher_home: {
      title: "היי ${firstName}",
      connect_google_calendar: "חיבור ליומן גוגל",
      connect_google_calendar_helper:
        "סנכרן את יומן Dio עם אירועים מ-Google Calendar",
    },
    teacher_trial_home: {
      trial_ends_label: "היי ${firstName}",
    },
    connect_calendar: {
      title: "סנכרון יומנים",
    },
  },
}
