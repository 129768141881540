import { createContext, useContext, useEffect, useState } from "react"
import { deleteCookie, getCookie, setCookie } from "../utils/cookies"
import { BASE_URL, get, post } from "../utils/requests"

interface Settings {
  token: string
  student: StudentDetails
  teacher: TeacherDetails
  events: EventDetails[]
  paymentMethods: PaymentMethodDetails[]
  user: UserDetails
  selectedTimeslots: Record<number, EventDetails>
}

const defaultSettings: Settings = {
  token: "",
  student: null,
  teacher: null,
  user: null,
  events: [],
  paymentMethods: [],
  selectedTimeslots: {},
}

interface SettingsContextProps {
  settings: Settings
  setSettings: React.Dispatch<React.SetStateAction<Settings>>
}

const defaultValue: SettingsContextProps = {
  settings: defaultSettings,
  setSettings: () => {},
}

const SettingsContext = createContext<SettingsContextProps>(defaultValue)

export const SettingsContextProvider = (props: any) => {
  const [settings, setSettings] = useState<Settings>(defaultSettings)
  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {props.children}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => {
  const { setSettings, settings } = useContext(SettingsContext)

  const pushToSettings = (value: Partial<Settings>) => {
    setSettings((prevSettings) => ({ ...prevSettings, ...value }))
  }

  const fetchSettings = async (token: string) => {
    if (!token) {
      return
    }
    const res = await get(`${BASE_URL}/settings`)
    pushToSettings(res)
  }
  const fetchEvents = async (token: string) => {
    if (!token) {
      return
    }

    const events = await post<EventDetails[]>(`${BASE_URL}/events`, {})

    const mappedEvents = (events || [])?.map?.((event) => ({
      ...event,
      startTime: new Date(event.startTime),
      endTime: new Date(event.endTime),
    }))

    if (!!events) {
      pushToSettings({
        events: mappedEvents,
      })
    }
  }
  const fetchPaymentMethods = async (token: string) => {
    if (!token) {
      return
    }

    const paymentMethods = await get<PaymentMethodDetails[]>(
      `${BASE_URL}/payments/cards`
    )

    if (!!paymentMethods) {
      pushToSettings({
        paymentMethods,
      })
    }
  }

  useEffect(() => {
    const token = getCookie("token") as string
    pushToSettings({ token })

    fetchSettings(token)
    fetchEvents(token)
    fetchPaymentMethods(token)
  }, [])

  const setSelectedSlots = (
    selectedTimeslots: Record<number, EventDetails>
  ) => {
    pushToSettings({ selectedTimeslots })
  }

  const setToken = (token: string) => {
    pushToSettings({ token })
    setCookie("token", token)

    fetchSettings(token)
  }

  const reloadSettings = async () => {
    const token = getCookie("token") as string
    await fetchSettings(token)
    await fetchEvents(token)
  }

  const resetSettings = () => {
    setSettings(defaultSettings)
    deleteCookie("token")
  }

  return {
    setSettings: pushToSettings,
    setToken,
    setSelectedSlots,
    reloadSettings,
    resetSettings,
    settings,
  }
}
