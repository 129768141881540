import clsx from "clsx"
import { useSettings } from "../../contexts/SettingsContext"
import classes from "./PaidHome.module.scss"
import { useCopies } from "../../contexts/CopiesContext"
import CalendarIcon from "../../icons/Calendar"
import SettingsItem from "../SettingsItem/SettingsItem"
import Divider from "../Divider/Divider"
import { useNavigate } from "react-router-dom"

const PaidHome = () => {
  const {
    settings: { paymentMethods },
  } = useSettings()

  const {
    copies: { teacher_home: copies },
  } = useCopies()

  const navigate = useNavigate()

  return (
    <div className={classes.root}>
      <div className={classes.settings}>
        <SettingsItem
          label={copies.connect_google_calendar}
          helper={copies.connect_google_calendar_helper}
          onClick={() => {
            navigate("/calendar/connect")
          }}
        />
      </div>
    </div>
  )
}

export default PaidHome
