import React, { useState } from "react"
import clsx from "clsx"
import classes from "./LessonSummary.module.scss"
import { useCopies } from "../../../contexts/CopiesContext"
import Button from "../../../components/Button/Button"
import NavigationBar from "../../../components/NavigationBar/NavigationBar"
import { useEffect } from "react"
import { getLocalTimeString, getRelativeDateLabel } from "../../../utils/time"
import { useSettings } from "../../../contexts/SettingsContext"
import { useNavigate } from "react-router-dom"
import { createEvent } from "../../../utils/events"
import { track } from "../../../utils/track"

const LessonSummary = () => {
  const {
    copies: { lesson_summary: copies, days, relative_dates },
  } = useCopies()
  const {
    settings: { selectedTimeslots, user },
    setSelectedSlots,
    reloadSettings,
  } = useSettings()

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const onTimeslotDelete = (slot: EventDetails) => {
    const startTime = new Date(slot.startTime)
    const timestamp = startTime.getTime()

    const selectedSlotsClone = { ...selectedTimeslots }
    if (timestamp in selectedSlotsClone) {
      delete selectedSlotsClone[timestamp]
      setSelectedSlots(selectedSlotsClone)
    }
  }

  useEffect(() => {
    if (Object.keys(selectedTimeslots).length < 1) {
      navigate(-1)
    }
  }, [selectedTimeslots])

  const onSubmitClick = async () => {
    setLoading(true)
    const tasks: Promise<Response>[] = []

    const timeslots = Object.values(selectedTimeslots)
    track("Request Lessons Press", { count: timeslots.length })
    for (const event of timeslots) {
      const eventServerView: EventServerView = {
        ...event,
        date: event.startTime,
        type: "lessonRequest",
        studentUID: user.id,
        ...(event.pickupLocation && {
          pickupLocation: event.pickupLocation.id,
        }),
        ...(event.dropoffLocation && {
          dropoffLocation: event.dropoffLocation.id,
        }),
        ...(event.car && {
          car: event.car.id,
        }),
      }
      tasks.push(createEvent(eventServerView))
    }

    const responses = await Promise.allSettled(tasks)
    setLoading(false)
    const error = responses.find(
      (resWithError) => resWithError?.["value"]?.error
    )

    setSelectedSlots({})

    if (!!error) {
      track("Request Lessons Error", {
        error: error?.["error"] || {
          message: "Unknown Error",
          code: "UNKNOWN_ERROR",
        },
      })
      return
    }
    const success = responses.every((response) => response?.["value"]?.success)
    if (success) {
      track("Request Lessons Success", { count: timeslots.length })
      await reloadSettings()
      navigate("Home")
      setLoading(false)
      navigate(-2)
    }
  }

  return (
    <>
      <div className={classes.root}>
        <NavigationBar title={copies.title} />
        <div className={clsx(classes.wrapper, "padded")}>
          <div className={classes.content}>
            {Object.values(selectedTimeslots).map((slot, index) => (
              <React.Fragment key={String(new Date(slot.startTime).getTime())}>
                <div className={classes.item}>
                  <div className={classes.itemContent}>
                    <span className={classes.timeLabel}>
                      {getRelativeDateLabel(
                        new Date(slot.startTime),
                        days.long,
                        relative_dates,
                        undefined,
                        undefined,
                        true
                      )}
                    </span>
                    <span className={classes.time}>
                      {getLocalTimeString(new Date(slot.startTime))} -{" "}
                      {getLocalTimeString(new Date(slot.endTime))}
                    </span>
                  </div>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => onTimeslotDelete(slot)}
                  >
                    {copies.cancel_lesson_cta}
                  </Button>
                </div>
                {index !== Object.keys(selectedTimeslots).length - 1 && (
                  <div className={classes.divider} />
                )}
              </React.Fragment>
            ))}
          </div>
          <div className={classes.button}>
            <Button onClick={onSubmitClick} disabled={loading}>
              {copies.cta_label}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default LessonSummary
