import { template } from "lodash"
import Header from "../../components/Header/Header"
import { useCopies } from "../../contexts/CopiesContext"
import { useSettings } from "../../contexts/SettingsContext"
import { useMemo } from "react"
import { isInFuture, isInPast, isWithinTimeRange } from "../../utils/time"
import EventsGroup from "../../components/Events/EventsGroup"
import clsx from "clsx"
import classes from "./Home.module.scss"
import AddIcon from "../../icons/Add"
import { useNavigate } from "react-router-dom"
import Button from "../../components/Button/Button"
import { track } from "../../utils/track"
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation"

const Home = () => {
  const {
    copies: { lessons: copies },
  } = useCopies()

  const {
    settings: { events },
  } = useSettings()

  const navigate = useNavigate()

  const getSubtitle = () => {
    if (events.length === 0) {
      return copies.subtitle_empty
    }

    if (events.length === 1) {
      return copies.subtitle_one_lesson
    }

    return template(copies.subtitle_multiple_lessons)({ number: events.length })
  }

  const subtitle = getSubtitle()

  const approvedEventTypes: EventType[] = ["lesson", "test", "internalTest"]

  const futureEvents = useMemo(
    () =>
      events.filter(
        (event) =>
          approvedEventTypes.includes(event.type) && isInFuture(event.startTime)
      ),
    [events]
  )
  const pastEvents = useMemo(
    () =>
      events.filter(
        (event) =>
          approvedEventTypes.includes(event.type) && isInPast(event.startTime)
      ),
    [events]
  )
  const activeEvent = useMemo(
    () =>
      events.filter(
        (event) =>
          approvedEventTypes.includes(event.type) &&
          isWithinTimeRange(event.startTime, event.endTime, new Date())
      ),
    [events]
  )
  const lessonsRequests = useMemo(
    () =>
      events.filter(
        (event) => event.type === "lessonRequest" && isInFuture(event.startTime)
      ),
    [events]
  )
  const cancelationRequests = useMemo(
    () =>
      events.filter(
        (event) =>
          event.type === "cancelationRequest" && isInFuture(event.startTime)
      ),
    [events]
  )

  const onHeaderCtaClick = () => {
    track("New Lesson Clicked", { origin: "Header" })
    navigate("/lesson/add")
  }

  const onEmptyButtonClick = () => {
    track("New Lesson Clicked", { origin: "Empty State" })
    navigate("/lesson/add")
  }

  return (
    <div className={classes.root}>
      <div className={clsx(classes.header, "padded")}>
        <Header
          title={copies.title}
          subtitle={subtitle}
          ctaLabel={copies.cta_label}
          onCtaClick={onHeaderCtaClick}
          ctaStartIcon={<AddIcon />}
        />
      </div>
      <div className={clsx(classes.wrapper, "padded")}>
        {(futureEvents || []).length === 0 && (
          <div className={classes.emptyWrapper}>
            <p>{copies.no_lessons}</p>
            <Button variant="outlined" onClick={onEmptyButtonClick}>
              {copies.schedule_lesson_cta}
            </Button>
          </div>
        )}
        <EventsGroup
          title={copies.lessons_in_progress}
          events={activeEvent}
          indicatorColor="#6690FF"
          isActive
        />
        <EventsGroup
          title={copies.waiting_for_approval}
          events={lessonsRequests}
          indicatorColor="#FFB323"
        />
        <EventsGroup
          title={copies.waiting_for_cancelation}
          events={cancelationRequests}
          indicatorColor="#FF5247"
        />
        <EventsGroup
          title={copies.future_lessons}
          events={futureEvents}
          indicatorColor="#4CD471"
        />
        <EventsGroup
          title={copies.lessons_history}
          events={pastEvents}
          indicatorColor="#E3E5E5"
        />
      </div>
      <BottomNavigation />
    </div>
  )
}

export default Home
