import clsx from "clsx"
import Header from "../../../components/Header/Header"
import { useCopies } from "../../../contexts/CopiesContext"
import classes from "./ConnectGoogleCalendar.module.scss"
import NavigationBar from "../../../components/NavigationBar/NavigationBar"
import { useSettings } from "../../../contexts/SettingsContext"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { BASE_URL, get, post } from "../../../utils/requests"
import Button from "../../../components/Button/Button"
import { setCookie } from "../../../utils/cookies"

const ConnectGoogleCalendar = () => {
  const {
    copies: { connect_calendar: copies },
  } = useCopies()

  const [loading, setLoading] = useState(true)
  const [loadingTokens, setLoadingTokens] = useState(false)
  const [googleTokens, setGoogleTokens] = useState<GoogleCalendarToken[]>([])

  const {} = useSettings()
  const navigate = useNavigate()

  const handleConnectGoogleCalendar = () => {
    const clientId =
      "1085055399421-9b8badu8u5hsvor8vj21751fk5ll2hdi.apps.googleusercontent.com"
    const redirectUri = "https://app.diodrive.co.il/calendar/connect"
    const scope =
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&prompt=consent`

    window.location.href = authUrl
  }

  // Handle OAuth Redirect
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get("code")
    const redirect = urlParams.get("redirect")

    if (redirect) {
      handleConnectGoogleCalendar()
    }

    if (code) {
      post(`${BASE_URL}/calendar/connect`, { code }).then(() =>
        window.location.replace("/calendar/connect?done=true")
      )
    } else {
      setLoading(false)
    }
  }, [])

  const fetchTokens = async () => {
    setLoadingTokens(true)
    const fetchedTokens = await get<GoogleCalendarToken[]>(
      `${BASE_URL}/calendar`
    )
    setGoogleTokens(fetchedTokens)
    setLoadingTokens(false)
  }
  // Handle OAuth Redirect
  useEffect(() => {
    fetchTokens()
  }, [])

  const onDeletePress = async (tokenId: number) => {
    await post(`${BASE_URL}/calendar/delete`, { tokenId })
    await fetchTokens()
  }

  if (loading) {
    return <div>טוען</div>
  }

  return (
    <div className={classes.root}>
      <div className={clsx(classes.header, "padded")}>
        <NavigationBar title={copies.title} />
      </div>
      <div className={clsx(classes.content, "padded")}>
        {/* <p>{copies.description}</p> */}

        <div className={classes.tokensList}>
          <h2 className={classes.tokensListTitle}>חשבונות מקושרים</h2>
          {googleTokens.length === 0 ? (
            <div>
              <p>אין חשבונות מקושרים</p>
              <Button
                onClick={handleConnectGoogleCalendar}
                className={classes.button}
              >
                חבר חשבון גוגל
              </Button>
            </div>
          ) : (
            <div className={classes.accountsList}>
              {googleTokens.map((token) => (
                <div key={token.id} className={classes.accountItem}>
                  <div className={classes.right}>
                    <p className={classes.name}>{token.userName}</p>
                    <h3 className={classes.title}>{token.email}</h3>
                  </div>
                  <div className={classes.left}>
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => onDeletePress(token.id)}
                    >
                      מחיקה
                    </Button>
                  </div>
                </div>
              ))}
              <Button
                onClick={handleConnectGoogleCalendar}
                className={classes.button}
              >
                הוסף חשבון נוסף
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConnectGoogleCalendar
