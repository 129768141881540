import clsx from "clsx"
import Header from "../../components/Header/Header"
import classes from "./Home.module.scss"
import { useCopies } from "../../contexts/CopiesContext"
import { useSettings } from "../../contexts/SettingsContext"
import { template } from "lodash"
import SettingsItem from "../../components/SettingsItem/SettingsItem"
import TrialHome from "../../components/TeacherHome/TrialHome"
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation"
import PaidHome from "../../components/TeacherHome/PaidHome"

const TeacherHome = () => {
  const {
    copies: { teacher_home: copies },
  } = useCopies()

  const {
    settings: { teacher, paymentMethods, user },
  } = useSettings()
  const title = template(copies.title)({ firstName: teacher.firstName })

  return (
    <div className={classes.root}>
      <div className={clsx(classes.header, "padded")}>
        <Header
          title={title}
          // ctaLabel={copies.cta_label}
          // onCtaClick={onHeaderCtaClick}
          // ctaStartIcon={<AddIcon />}
        />
      </div>

      <div className="fullHeight">
        {user.plan === "MONTHLY" && user.renewals === 0 && <TrialHome />}
        {user.plan === "MONTHLY" && user.renewals > 0 && <TrialHome />}
        {/* <TrialHome /> */}
      </div>
    </div>
  )
}

export default TeacherHome
