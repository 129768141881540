import clsx from "clsx"
import CalendarIcon from "../../icons/Calendar"
import CloseIcon from "../../icons/Close"
import classes from "./SettingsItem.module.scss"

interface SettingsItemProps {
  label: string
  helper?: string
  color?: string
  onClick(): void
}

const SettingsItem = (props: SettingsItemProps) => {
  const { label, helper, color = "#000", onClick } = props
  return (
    <div className={clsx(classes.root, "padded")} onClick={onClick}>
      <CalendarIcon color={color} className={classes.icon} />
      <div className={classes.content}>
        <h3 className={classes.title}>{label}</h3>
        <p className={classes.subtitle}>{helper}</p>
      </div>
    </div>
  )
}

export default SettingsItem
