import clsx from "clsx"
import BottomNavigation from "../../../components/BottomNavigation/BottomNavigation"
import Header from "../../../components/Header/Header"
import { useCopies } from "../../../contexts/CopiesContext"
import classes from "./Settings.module.scss"
import CloseIcon from "../../../icons/Close"
import { useSettings } from "../../../contexts/SettingsContext"
import { useNavigate } from "react-router-dom"

const Settings = () => {
  const {
    copies: { settings: copies },
  } = useCopies()

  const { resetSettings } = useSettings()

  const navigate = useNavigate()
  const onLogout = () => {
    resetSettings()
    navigate("/")
  }
  return (
    <div className={classes.root}>
      <div className={clsx(classes.header, "padded")}>
        <Header title={copies.title} />
      </div>
      <div className={clsx(classes.content, "padded")}>
        <div className={classes.item} onClick={onLogout}>
          <CloseIcon color="#FF5247" />
          <span className={classes.itemLabel}>{copies.logout}</span>
        </div>
      </div>
      <BottomNavigation />
    </div>
  )
}

export default Settings
